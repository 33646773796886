import React, { useEffect, useState } from 'react'

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <nav id='menu' className='navbar-fixed-top' >{/**/}
      <div className='container-fluid top-1'>
        <div>
          <div className="container top-bar">
            <div className="col-md-5 col-md-5 col-xs-12" style={{marginTop:'15px'}}>
              <a href="/">
                <img className="img-responsive logo" id="logo" src="img/logo2.png" alt='roservicelogo' />
              </a>
            </div>
            <div className="col-sm-6 pull-right">
              <h2 className="charge_box">RO Service Charge Start
                <span>@ 299/-</span>
              </h2>
              {/*
                <div className="call_us pull-right">
                <span className="customercarenumber">
                  <strong><a href="tel:9911136123">
                    <span className="fnt">Call Us: </span>
                    9911136123 
                  </a></strong>
                </span>
              </div>
               */} 
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
