export const About = (props) => {
  return (

    <div className="container">
      <div id="about">
        <h1 className="aboutheader-1">RO Customer Care Number &amp; Service Centre in <br />
          <span className="aboutheader-2"> Delhi NCR  </span>
        </h1>
      </div>

      <div align="center" className="border">
        <img className="img-responsive" src="img/border.jpg" alt='ro service aboutus' />
      </div>
      &nbsp;
      <p style={{ align: "justify" }}>If you're experiencing any issues with your RO water purifier, whether in Delhi NCR or anywhere across India, our customer care team is here to assist you. Simply call from your registered mobile number <b><a href="tel:9911136123 ">9911136123 </a></b>, and you'll receive a prompt callback along with a complaint number. Our skilled technicians will visit your location the same day to provide expert repair and service, ensuring your system is restored quickly.
      </p>

      <p style={{ align: "justify" }}>As one of the most trusted brands in the industry, RO CARE HELPLINE has earned a reputation for delivering high-quality water purification systems, trusted in homes and offices alike. Our systems are known for their reliability and durability, and we offer comprehensive service coverage with repair, AMC (Annual Maintenance Contract), and installation services available through our extensive network of service centers across India.<br /><br />

      Our technicians are highly trained to handle all maintenance tasks, including membrane cleaning and other essential repairs, either at your doorstep or at one of our regional service centers. We’re committed to providing full-service solutions for all types of water purification systems, helping you save both time and money while ensuring optimal performance.<br /><br />

      RO CARE HELPLINE is a leading provider of water treatment solutions for domestic, commercial, and industrial needs. You can trust us to handle all your RO system service requirements with expertise and efficiency.<br /><br />

        &nbsp;
      </p>
      {/*<p align="center">
        <a className="btn btn-primary btn-lg" href="tel:9911136123 " target="_blank" rel="noreferrer" style={{ fontSize: '24px' }}>Call Now: 9911136123 </a>
      </p> */}
      <p>For installation, routine maintenance, or emergency service, our RO service center has you covered. Our team of professional technicians is available 24/7, offering nationwide support across nearly every state in India. Whether you need repairs, maintenance, or any other service, we’re always here to help.<br />
        &nbsp;
      </p>
      <p></p>
      <p></p>
      <p></p>

      <p><strong>RO CARE Helpline Service Center Number Locations &  Contact Number</strong></p>

      <table class="table fntt" style={{ border: "1px solid #ccc" }}>
        <thead>
          <tr>
            <th>RO CARE Helpline Customer Care Number Delhi NCR</th>
            <th>RO CARE Helpline Contact Number Delhi NCR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>RO CARE Helpline Service Center Number Delhi NCR</td>
            <td><a href="tel:9911136123 "><strong>9911136123 </strong></a></td>
          </tr>
          <tr>
            <td>RO CARE Helpline Service Center Number Delhi NCR</td>
            <td><a href="tel:9911136123 "><strong>9911136123 </strong></a></td>
          </tr>

        </tbody>
      </table>

    </div>

  );
};
