import { useState, useEffect } from "react";
import JsonData from "./data/data.json";


import { Navigation } from './components/navigation';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import { Copyright } from "./components/copyright";

import { Footer } from "./components/footer";
import Home from "./Home";
import { PrivacyPolicies } from './components/pages/privacypolicy'

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">

      <div className="social">
      <div>
      <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919911136123" target="_blank">
        <i className="fa fa-whatsapp"></i>
      </a>
    </div>
          <div className="pop">
          <a className="phonescreen" href="tel:9911136123 ">
            <img style={{ border: "0" }} src="img/banners/quickenquiry.png" alt="callus@9911136123 " />
          </a>
        </div>
          
      </div>

      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies />} />

      </Routes>
      <Footer />
      <Copyright />
    </div>
  );
}

export default App;
